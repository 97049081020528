'use client'

import dynamic from 'next/dynamic'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid2 as Grid, Typography } from '@mui/material'

// Types
import type { CardCoursesProps } from '@/types/components/atoms/cards/courses'

// Components
const ClockCardAtom = dynamic(() => import('@/components/atoms/icons/clock-card'))
const TopicCardAtom = dynamic(() => import('@/components/atoms/icons/topic-card'))
const DateCardAtom = dynamic(() => import('@/components/atoms/icons/date-card'))

const CardCoursesAtom = (props: CardCoursesProps) => {
	const { title, hours, date, teacherName, teacherImage, cardImage } = props

	return (
		<Card sx={{ width: '100%', boxShadow: '2px 3px 10px rgba(217, 217, 217, 0.5)', bgcolor: '#fefcf8' }} elevation={5}>
			<CardMedia sx={{ height: 230 }} image={cardImage} title={title} />
			<CardContent sx={{ px: 1.75 }}>
				<Grid container spacing={1}>
					<Grid display="flex" alignItems="center">
						<TopicCardAtom />

						<Typography display="inline" fontFamily={'calibri'} color="text.primary" fontSize={{ xs: 12, md: 14 }} ml={1}>
							{title}
						</Typography>
					</Grid>

					<Grid display="flex" alignItems="center">
						<ClockCardAtom />

						<Typography display="inline" fontFamily={'calibri'} color="text.primary" fontSize={{ xs: 12, md: 14 }} ml={1}>
							{hours} hrs
						</Typography>
					</Grid>

					<Grid display="flex" alignItems="center">
						<DateCardAtom />
						<Typography display="inline" fontFamily={'calibri'} color="text.primary" fontSize={{ xs: 12, md: 14 }} ml={1}>
							{date}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions>
				<Button>
					<Typography fontFamily={'calibri'} color="text.primary" fontSize={{ xs: 16, md: 20 }} fontWeight={700}>
						Tłı̨chǫ Agreement Course
					</Typography>
				</Button>
			</CardActions>
			<CardHeader
				avatar={
					<Avatar alt={teacherName} src={teacherImage} sx={{ bgcolor: 'primary.light' }}>
						{teacherName ? teacherName.charAt(0).toUpperCase() : null}
					</Avatar>
				}
				title={teacherName}
				sx={{ '& .MuiTypography-root': { color: 'primary.main', fontWeight: 700 } }}
			/>
		</Card>
	)
}

export default CardCoursesAtom
